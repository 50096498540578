import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RefreshHandlerService {
  public isRefreshButtonClicked: BehaviorSubject<boolean> = new BehaviorSubject(
    false,
  );

  constructor() {}

  public refreshButtonWasClicked(val: boolean): void {
    this.isRefreshButtonClicked.next(val);
  }

  public getRefreshButtonState(): Observable<boolean> {
    return this.isRefreshButtonClicked.asObservable();
  }
}
